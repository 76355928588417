import { baseUrl } from '../baseUrl.js';

export const getqrimage = async (token,swish_token2)=>{
    const data = {
        swish_token: swish_token2
      };
    console.log('token2',swish_token2)
    try {
        const response = await fetch(baseUrl+'/api/getqrimage/', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+token
          },
          body: JSON.stringify(data),
          method: "POST"
        })
    
        if (response.status === 200) {
            console.log(response)
          return response;
        }
      } catch (error) {
        console.error(error);
      }

} 

export const initate_membership_desktop2 = async (token,membershipID,amount) => {
    //TODO: Om jag tar bort return här så öppnas swish appen
    // men då funkar inte desktop varianten
    var response = await fetch(baseUrl+'/api/membership/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      body: JSON.stringify({'membershiptypeID':membershipID,'amount':amount}),
      })
    if (response.status==201){
        console.log(response)
        return response.json();
    }
    else if(response.status==208){
      return {'redirect':'alreadypaid'};
    }
    else if(response.status==401){
      return ({'status':'Unauthorized'});
    }
    else if(!response.ok) 
    {
        throw response;
    }
      
    
  };
//   export const initate_membership_desktop = async (token) => {
//     //TODO: Om jag tar bort return här så öppnas swish appen
//     // men då funkar inte desktop varianten
//     fetch(baseUrl+'/membership/',{
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':'Bearer '+token
//       },
//       body: JSON.stringify({}),
//       })
//       .then((response) => {
//         if (response.status==201){
//           return response.json();
//         }
//         else if(!response.ok) 
//         {
//           throw response;
//         }
//       })
//     .catch((err) => {
//       //TODO: catch response and update loginpage with error.
//       console.log(err);
//     });
//  };
//   export const initate_membership_mobile = async (token) => {
//     //TODO: Om jag tar bort return här så öppnas swish appen
//     // men då funkar inte desktop varianten
//     var response = await fetch(baseUrl+'/membership/',{
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':'Bearer '+token
//       },
//       body: JSON.stringify({}),
//       })
//       .then((response) => {
//         if (response.status==201){
//             console.log(response)
//           return response.json();
//         }
//         else if(!response.ok) 
//         {
//           throw response;
//         }
//       })
//     .catch((err) => {
//       //TODO: catch response and update loginpage with error.
//       console.log(err);
//     });
//     return response;
//   };