import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../hooks/useAuth";
import { useRoadevent } from "../hooks/useRoadevents";
import { useFikapass } from "../hooks/useFikapass";
import './register.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const SignUp = (props) => {
  const { children, value, index, ...other } = props;

  const { login,error } = useAuth();
  const { getRoadevents } = useRoadevent();
  const { getRundor,getFik,getKort,getSurface } = useFikapass();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    

    login({
      username: data.get("username").toLowerCase(),
      password: data.get("password")
    }).then((data)=>{
      getFik({},window.localStorage.getItem('token').slice(1,-1));
      getKort({},window.localStorage.getItem('token').slice(1,-1));
      getSurface();
  });
    getRoadevents();
    getRundor();
  };
  return (
    <div role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}><div />
        
        <Typography component="h1" variant="h5">
          Logga in
        </Typography>
        {error?.loginerror?<Typography component="p" >
        {error.loginerror.detail}
        </Typography>:<></>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="login_username"
            label="Username"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="login_password"
            autoComplete="current-password"
          />
          <Typography sx={{textAlign:'end'}}>
            Glömt lösenord? <a href={'forgot_pass/'} className='forgot-pass'>Klicka här</a>
          </Typography>
          <Button
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            className='reg-button'
          >
            Logga in
          </Button>
        </Box>
        </div>
  )
}
export const Register = (props) => {

  const { children, value, index, ...other } = props;

  const { register } = useAuth();

  const handleSubmit2 = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    register({
      username: data.get("username").toLowerCase(),
      password: data.get("password"),
      password2: data.get("password2"),
      first_name: data.get("firstname"),
      last_name: data.get("lastname"),
      email: data.get("email").toLowerCase(),
      profile:{
        firstname: data.get("firstname"),
        lastname: data.get("lastname"),
        phone: data.get("telefonnummer"),
        socialnumber: data.get("personnummer"),
        email: data.get("email").toLowerCase(),
        co: data.get("co"),
        adress: data.get("gatuadress"),
        postalcode: data.get("postnummer"),
        city: data.get("postort")}
    });
    //getRoadevents();
    //getRundor();
    //setValue2(data);
  };
  return (
    <div role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    <div />
        <Typography component="h1" variant="h5">
          Registrera konto
        </Typography>
        <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 1 }}>
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            id="username"
            label="Användarnamn"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            name="password"
            label="Lösenord"
            type="password"
            id="password"
            sx={{ width:'45%',paddingRight:'5%' }}
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            name="password2"
            label="Bekräfta lösenord"
            type="password"
            id="password2"
            sx={{ width:'50%' }}
          />
          <TextField
            size="small"
            margin="dense"
            fullWidth
            name="telefonnummer"
            label="Telefonnummer"
            id="telefonnnummer"
            sx={{ width:'45%',paddingRight:'5%' }}
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            name="personnummer"
            label="Födelsedatum (YYYYMMDD)"
            id="persnr"
            sx={{ width:'50%'}}
          />
          <TextField
            size="small"
            margin="dense"
            required
            fullWidth
            name="email"
            label="E-mail"
            type="email"
            id="email"
          />

          <TextField
            size="small"
            margin="dense"
            required
            name="firstname"
            label="Förnamn"
            id="firstname"
            sx={{ width:'45%',paddingRight:'5%' }}
          />
          <TextField
            size="small"
            margin="dense"
            required
            name="lastname"
            label="Efternamn"
            id="lastname"
            sx={{ width:'50%' }}
          />
          <TextField
              size="small"
              margin="dense"
              fullWidth
              name="co"
              label="c/o"
              id="co"
          />
          <TextField
              size="small"
              margin="dense"
              fullWidth
              name="gatuadress"
              label="Gatuadress"
              id="gata"
          />
          <TextField
              size="small"              
              margin="dense"
              name="postnummer"
              label="Postnummer"
              id="postnr"
              sx={{ width:'45%',paddingRight:'5%' }}
              />
          <TextField
              size="small"
              margin="dense"
              name="postort"
              label="Postort"
              id="postort"
              sx={{ width:'50%'}}
          />
          <Typography sx={{textAlign:'end'}}>
            För information kring hur vi hanterar persononuppgifter <a href={'gdpr/'} className='forgot-pass'>Klicka här</a>
          </Typography>
          <Button
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            className='reg-button'
          >
            Skapa konto 
          </Button>
        </Box>
        </div>
  )
}

export const RegisterLogin = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} >
            <Tab label="Logga in" {...a11yProps(0)} />
            <Tab label="Registrera" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <SignUp value={value} index={0}></SignUp>
        <Register value={value} index={1}></Register>
      </Box>
    </Container>
  );
};
