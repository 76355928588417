import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
//import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {TableRow,Table,TableHead,TableBody,TableCell,TableContainer} from "@mui/material";

import { isMobile } from 'react-device-detect';
import { initate_membership_desktop2, getqrimage } from "../../hooks/membershipApi";
import { useAuth } from "../../hooks/useAuth";
import { baseUrl } from '../../baseUrl.js';
import './profile.css'
import '../text.css';
import '../register.css'
//Dummydata som bör komma från Context

const modalStyle = {
    position:'absolute',
    left:'50%',
    top:'50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight:'90%',
    backgroundColor: 'white',
    boxShadow: 24,
    margin:'auto',
    border: 'black',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '15px',
    padding:'10px'
  };
  
function checkActive(memberships){
    if (!memberships){
        return false
    }
    const today2 = new Date();
    var membershipvalidity = false;
    if (JSON.stringify(memberships) === '{}'){
        console.log('Empty')
        membershipvalidity = false;
    }
    else {
        membershipvalidity= memberships.some(membership=>(membership.membership_type.valid_to!=null && (new Date(parseInt(membership.membership_type.valid_to.split('-')[0]),membership.membership_type.valid_to.split('-')[1],membership.membership_type.valid_to.split('-')[2])>today2)))
    }
    return membershipvalidity
}
function checkBeginningOfYear(memberships){
    var membershipvalidity = false;
    var d = new Date(new Date().getFullYear()+1, 0, 1);

    if (JSON.stringify(memberships) === '{}'){
        membershipvalidity = false;
    }
    else {
        membershipvalidity= memberships.some(membership=>(membership.membership_type.valid_to!=null && (new Date(parseInt(membership.membership_type.valid_to.split('-')[0]),membership.membership_type.valid_to.split('-')[1],membership.membership_type.valid_to.split('-')[2])<d)))
    }
    return membershipvalidity
}

export function MembershipPayment({memberships}) {
    const [img, setImg] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [swishMobileUrl, setSwishMobileUrl] = useState('');
    const handleClose = () => setModalOpen(false);
    const navigate = useNavigate();
    const { token,logout } = useAuth();
    const swishPay_desktop2 = async (token,amount,membershipID)=>{
        const swishtoken = await initate_membership_desktop2(token,membershipID,amount);
        if('status' in swishtoken){
            if(swishtoken.status=='Unauthorized'){
                logout();
                return
            }
        }
        const res = await getqrimage(token,swishtoken.swish_token);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL);
        setModalOpen(true);
    }
    //Funkar i chrome på ios och safari ios
    const swishPay_mobile2 = async (token,amount,membershipID)=>{
        const membership_info = await initate_membership_desktop2(token,membershipID,amount);
        var membership_parsed = membership_info;
        if ('redirect' in membership_parsed){
            navigate("/alreadypaid", { replace: true });
        }

        var json_encoded = await encodeURIComponent(JSON.stringify({
            "version":1,
            "payee":{
            "value":"1231974526"
            },
            "amount":{
            "value":amount
            },
            "message":{
            "value":"Medlemskap "+membership_parsed.ocr,
            "editable":false
            }
            }));
        console.log('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/membership2/'+membership_parsed.membership_id+'/&callbackresultparameter=res')
        //setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=http://192.168.0.109:8000/membership2/'+membership_parsed.membership_id+'/&callbackresultparameter=res')
        setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/membership2/'+membership_parsed.membership_id+'/&callbackresultparameter=res')
        
        setModalOpen(true);
    };
    const [name, setName] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [membershipID, setMembershipID] = React.useState(0);

    const handleChange = (event) => {
        setName(event.target.value);
      };

      const handleChange2 = (membership) => {
        setAmount(membership.amount);
        setMembershipID(membership.id);
      };
      console.log(swishMobileUrl);
    return (<>
                <h1>
                    Köp medlemskap
                </h1>
                <p>
                    Medlemskapet i föreningen cykelintresset kostar  i dagsläget 200kr. Varken summan eller meddelandet går att ändra när betalning sker. 
                </p>
                <p>
                    Genom att bli medlem i cykelintresset så godkänner du även vår <a href='/antidoping'>antidopingpolicy</a>.
                </p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Medlemskap</InputLabel>
                  <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={name}
                        label="Medlemskap"
                        onChange={handleChange}
                  >
                    {memberships.map((membership)=>
                      <MenuItem value={membership.name} onClick ={()=>handleChange2(membership)}>{membership.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Box component="form" onSubmit={console.log('Skapa endpoint')} noValidate sx={{ mt: 1 }}>
                    <div style={{'marginLeft':'50%','transform': 'translate(-50%, 0%)'}}>
                        {isMobile?
                        <Button
                            onClick={()=>swishPay_mobile2(token,amount,membershipID)}
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            className='reg-button'
                        >Öppna Swish och slutför betalning</Button>:<Button
                        onClick={()=>swishPay_desktop2(token,amount,membershipID)}
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        className='reg-button'
                    >Skapa QR-kod</Button>
                    }
                    </div>
                <Modal disableEnforceFocus
                    BackdropProps={{style: {backgroundColor: 'white',borderRadius:'15px'}}}
                    sx={modalStyle}
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >

                    {isMobile?
                    <Box align="left">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                        position: 'relative',
                        right: 8,
                        top: 0,
                        color: 'black',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>Fortsätt till betalning genom att öppna swish.</Typography>
                        <Button
                            onClick={()=>window.open(swishMobileUrl, "_blank")}
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            className='reg-button'
                        >Öppna swish appen</Button>
                    </Box>:
                    <Box align="center">
                        <img src={img} 
                             data='image/jpeg;base64' 
                             alt="icons" 
                             style={{'width':'200px',paddingTop:'10px'}}/>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Fullfölj betalningen genom att scanna qrkoden med kameran på en enhet som har swish. Och följ anvisningar på den enheten.
                        </Typography>
                        <Button
                            onClick={()=>setModalOpen(false)}
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            className='reg-button'
        
                        >Avbryt betalning</Button>
                    </Box>
                    }
                </Modal>
                </Box>
            </>)
            }


function historicalMemberships(memberships) {
    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{'background':'#999'}}>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>Giltigt till</TableCell>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>ocr</TableCell>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>Betaldatum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberships.map((list, index) => ( 
                         <TableRow sx={{'padding':2}} key={index}>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>{list.membership_type.valid_to}</TableCell>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>{list.payment.ocr}</TableCell>
                            <TableCell sx={{'padding':'2px', 'textAlign':'center'}}>{list.payment.paid_date}</TableCell>
                             
                         </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
        </div>
    )
}
export default function Membership2() {

    const { membership,profile } = useAuth();
    const [ activememberships,setActivememberships ] = useState([]);
    
    useEffect(() => {
        fetch(baseUrl+'/api/activememberships/')
          .then(results => results.json())
          .then(data => {
            setActivememberships(data);
          });
      }, []); // <-- Have to pass in [] here!
    return (

            <div className="textsida">
                {checkActive(membership)?<>
                    <h3>Aktivt medlemskap i Cykelintresset</h3>
                    <p>{profile.firstname} {profile.lastname} är aktiv medlem i Cykelintresset.</p>
                    <h3>Rabattkoder 2024</h3>
                    <p>Rabattkod för BP 110km Håknäs 25 Maj CI2024</p>
                    <p>Rabattkod för BP 80km Rödåsel 8 Juni CI2024</p>
                    <p>Rabattkod för Glassbonden Gravel 25 Augusti är Challange är Grusgrus2024</p>
                    <p>Rabattkoder är endast till för betalande medlemmar.</p>
                  </>:<>
                    <h1>Inaktivt medlemskap</h1>
                    <h3>Medlemsavgift</h3>
                    <p>Medlemskap går endast att betala med mobiltelefon som har swish installerat. Saknar du möjlighet att betala med swish så kontakta oss på cykelintesset@gmail.com</p>
                    <MembershipPayment memberships={activememberships} />
                  </>
                }
                {(checkActive(membership)==true & checkBeginningOfYear(membership)==true)?<><MembershipPayment memberships={activememberships} /></>:<><p>När det är dags att förnya medlemskapet kommer det finnas mer information här.</p></>}
            <h1>Historiska medlemskap</h1>
            {JSON.stringify(membership) === '{}'?<></>:historicalMemberships(membership)}
            </div>
            );}