import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";

export default function ForgotPass(){

    const { reset,reset_password } = useAuth();


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        reset_password(data.get("username").toLowerCase())
        }; 
      
    return <>
            <Typography component="h1" variant="h5">
                Reset Password
            </Typography>
            {reset['sent']?<p>Mail med återställningslänk skickad. Om du inte får något mail. Kontrollera mailadressen eller kontakta oss.</p>:<></>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                margin="dense"
                required
                fullWidth
                id="login_username"
                label="E-mail"
                name="username"
                autoComplete="email"
                autoFocus
                />
                
                <Button
                type="submit"
                fullWidth
                sx={{ mt: 3, mb: 2,}}
                //disabled={reset['sent']}
                className='reg-button'
                >
                Skicka länk för reset av lösen
                </Button>
            </Box>
          </>
}