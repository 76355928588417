import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { isMobile } from 'react-device-detect';
//import { baseUrl } from '../../baseUrl.js';
const baseUrl = process.env.NODE_ENV === 'development' ? window.location.origin.split(':3000')[0]+':8000' : 'https://www.cykelintresset.se'
export default function PayMembershipFromRedirect(){
    let { uid } = useParams();
    //const [membershipdetails, setMembershipdetails] = useState(null);
    const [swishMobileUrl, setSwishMobileUrl] = useState(null);
    console.log('Loaded')
    console.log('UID',uid)
    async function fetchData(){
      const res = await  fetch(baseUrl+'/api/membership/'+uid+'/',{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }})
      const data = await res.json()
      console.log(data)
      const  json_encoded = await encodeURIComponent(JSON.stringify({
        "version":1,
        "payee":{
        "value":"1231974526"
        },
        "amount":{
        "value":data.payment.amount
        },
        "message":{
        "value":"Medlemskap "+data.payment.ocr,
        "editable":false
        }
        }));
        setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/membership2/'+data.id+'/&callbackresultparameter=res');
        
      console.log(data)
    }
    useEffect(() => {
      fetchData()
      // fetch(baseUrl+'/api/membership/'+uid+'/')
      //   .then(results => results.json())
      //   .then(data => {
      //     //setMembershipdetails(data);
      //     //console.log(data)
      //     var json_encoded = encodeURIComponent(JSON.stringify({
      //       "version":1,
      //       "payee":{
      //       "value":"1231974526"
      //       },
      //       "amount":{
      //       "value":data.payment.amount
      //       },
      //       "message":{
      //       "value":"Medlemskap "+data.payment.ocr,
      //       "editable":false
      //       }
      //       }));
          
      //  });
    }, [uid]); // <-- Have to pass in [] here!
    isMobile=true
    console.log('Before rendering')
    console.log('SwishmobileUrl',swishMobileUrl)
    return  (<><Typography component="h1" variant="h5">
                Betala medlemskap
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {swishMobileUrl?
                  <Button
                  onClick={()=>window.open(swishMobileUrl, "_blank")}
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      className='reg-button'
                  >Öppna Swish och slutför betalning</Button>:<></>
                }
              </Box>




  </>)
}