import React, {useState, useRef, useEffect,} from "react";
import { MapContainer, TileLayer, Marker, Circle, Popup,Polyline,LayersControl,LayerGroup,  FeatureGroup,
  Rectangle,useMapEvents,useMap } from 'react-leaflet';
import './Map2.css';
//import { Icon } from "leaflet";
import LayerControl, { GroupedLayer } from "./LayerControl";
import 'leaflet/dist/leaflet.css';
//import * as L from "leaflet-gpx";
import L from "leaflet";
import Tracker from './Tracker';
import geolocation from 'geolocation'
//import gpxParser from 'gpxparser';
//import {gpx1} from './gpx/gpx.js';
//import {fik2} from './fake_api/fik.js';
import Button from '@mui/material/Button';
import { Outlet, Link } from "react-router-dom";
import { useRoadevent } from "../../hooks/useRoadevents";
import { useFikapass } from "../../hooks/useFikapass";
import { useMyPosition } from "../../hooks/useMyPosition";
import roadworkIcon from "./img/roadwork.png"
import coffeeIcon from "./img/coffe.png"
import { useAuth } from "../../hooks/useAuth";
import {checkin} from "../../hooks/fikapassApi"
import { ConstructionOutlined, Logout } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import MyLocation from '@mui/icons-material/MyLocation';
const position = [63.833, 20.28];

//delete L.Icon.Default.prototype._getIconUrl;

// function MapEventsHandler({ pos }) {
//   const map = useMap();
//   map.on('click', (e) => {
//      const { lat, lng } = e.latlng;
//     map.setView(pos, map.getZoom());
//    });
//   return null;
// }

function ZoomTo({map,lat,lng}) {
  //const map = useMap();

    map.setView([lat,lng], map.getZoom());

  return null;
}
function createIcon(url) {
  return new L.Icon({
    iconUrl: url,
    iconSize: [30, 30]
  });
}
// uppdatera kartstorleken så att den är återanvändingsbar
// https://github.com/PaulLeCam/react-leaflet/issues/340
const resizeMap = (mapRef) => {
  const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize())
  const container = document.getElementById('map-container')
  if (container) {
    resizeObserver.observe(container)
  }
}

export default function Map2() {
  const { roadevent } = useRoadevent();
  const { rundor,fik,kort,getKort } = useFikapass();
  const {token, logout} = useAuth();
  const {latitude,longitude, error} = useMyPosition(true);
  const mapRef = useRef();
  //const uMap = useMap();
  const [map, setMap] = useState(null);
  const popupElRef = useRef(null);

  const checka_in = async (token, kort,kontroll )=>{
    var res = await checkin(token, kort, kontroll)
    
    if('status' in res){
      console.log(res)
      if(res['status']==='alreadycheckedin'){ 
        alert('Du har redan checkat in här. Testa ett annat fik.')

      }
      else if (res.status=='Unauthorized'){
        alert('Din session har gått ut. Logga in igen och ckecka in på nytt!')
        logout();
      }
      else if (res.status=='ok'){
        alert('Du har checkat in! Ha en fortsatt trevlig tur')
        getKort({},token)

      }
    }
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click()
  
    return
  }
  var closeTo = (fiket,latitude=0,longitude=0)=>{
    return (L.latLng(JSON.parse(fiket.fiket.latlon).reverse()).distanceTo([latitude,longitude])<550)
  }

  
  if (!latitude|!latitude){
    //latitude=0;
    //longitude=0;
    resizeMap(mapRef)
    //return <></>
  }
  // Kan vara användbart för att göra bättre funktionalitet kring drop down menyn för lager.
  // function CloseOnClick() {
  //   //const [position, setPosition] = useState(null)
  //   const map = useMapEvents({
  //     click() {
  //       map.locate()
  //     },
      
  //     locationfound(e) {
  //       console.log('test')
  //       //setPosition(e.latlng)
  //       //map.flyTo([0,0], map.getZoom()
  //     //)
  //     },
  //   })
  
  //   return <></>
  // }
  return (
    <MapContainer renderer={L.canvas({ tolerance: 30 })}  ref={mapRef} id="map-container" whenReady={() => resizeMap(mapRef)} center={latitude && longitude ? [latitude, longitude] : position} zoom={13}  scrollWheelZoom={true}>
    {/* {latitude &&<MapEventsHandler pos={[latitude,longitude]}/>} */}
    {/* <MapContainer renderer={L.canvas({ tolerance: 30 })}  ref={mapRef} id="map-container" whenReady={() => resizeMap(mapRef)} center={position} zoom={13}  scrollWheelZoom={true}> */}
      {/* <CloseOnClick/> */}
      {/* <LocationMarker /> */}
      <IconButton
        //type="button"
        onClick={()=>{ZoomTo({map: mapRef.current,lat:latitude,lng:longitude});}}
        style={{position: 'absolute', bottom: '20px',right:'10px',zIndex: '10000','backgroundColor':'white'}}
      >
        <MyLocation />
        </IconButton>
        {/* Var är jag? */}
      {/* <button
        type="button"
        onClick={()=>{ZoomTo({map: mapRef.current,lat:latitude,lng:longitude});}}
        style={{position: 'absolute', bottom: '30px',right:'10px',zIndex: '10000'}}
      >
        Min Position
      </button> */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
     <LayerControl position="topright">
     <GroupedLayer checked group="Positioner" name="Min position" key="myposition">
        <Tracker></Tracker>
      </GroupedLayer>

      <GroupedLayer checked group="Positioner" name="Trafikvarningar" key="positioner">
        <LayerGroup>
          {roadevent?roadevent.map((road, index) => (
            road['Deviation'].map((roadevent,index2)=>(
              roadevent['MessageCodeValue']=="roadworks"?
              <Marker icon={createIcon(roadworkIcon)} key={index2} position={roadevent['Geometry']['WGS84'].slice(7,-1).split(' ').map((str)=>Number(str)).reverse()}>
              <Popup key={index2}>
                {roadevent['LocationDescriptor']}<br/>
                {roadevent['Message']}<br/>
                <span style={{fontWeight:'600'}}>Starttid: </span>{roadevent['StartTime'].slice(0,10)} {roadevent['StartTime'].slice(11,16)} <br/>
                <span style={{fontWeight:'600'}}>Sluttid: </span>{roadevent['EndTime'].slice(0,10)} {roadevent['EndTime'].slice(11,16)}<br/>
              </Popup>
          </Marker>:<div key={'empty'+index2}></div>
            )
          ))):<></>}
        </LayerGroup>
      </GroupedLayer>
 
      <GroupedLayer checked group="Positioner" name="Fik" key="fik">
        <LayerGroup>
          {fik?fik[0]['kontroller'].map((fiket, index) => (
            <Marker key={'fik-'+index} position={JSON.parse(fiket.fiket.latlon).reverse()} icon={createIcon(coffeeIcon)} >
               <Popup ref={popupElRef}>
                <h4>{fiket.fiket.namn}</h4><br/>{fiket.fiket.description}
                <Button
                  onClick={()=>checka_in(token,kort[0].id,fiket.id).then(res=>{})}
                    sx={{ my: 2, color: 'blue', display: 'block' }}
                    key={fiket+'-knapp'}
                    disabled={!closeTo(fiket,latitude,longitude)}
                >
                    Checka in
                </Button>
              </Popup>
              <Circle center={JSON.parse(fiket.fiket.latlon).reverse()} pathOptions={{color:'black',weight:0}} radius={500} />
          </Marker>
          )):<></>}
        </LayerGroup>
      </GroupedLayer>

      {/* <GroupedLayer checked group="Positioner" name="Fik" key="fik">
        <LayerGroup>
          {fik2.map((fiket, index) => (
            <Marker key={'fik-'+index} position={fiket.latlon} icon={createIcon(coffeeIcon)} >
              <Popup>
                {fiket.name}<br/>{fiket.description}
                <Button
                    component={Link} to="/Map"
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    Checka in
                </Button>
              </Popup>
              <Circle center={fiket.latlon} radius={200} />
          </Marker>
          ))}
        </LayerGroup>
      </GroupedLayer> */}
      <GroupedLayer checked name="OpenStreetMap" group="Bakgrundskarta" key="backgroundmap1">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">Stadia Maps</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </GroupedLayer>
      {/* <GroupedLayer  name="OpenStreetMap - Dark" group="Bakgrundskarta" key="backgroundmap2">
        <TileLayer
          attribution='&copy; <a href="https://docs.stadiamaps.com/themes/">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        />
      </GroupedLayer> */}
      {/* <GroupedLayer name="OpenStreetMap CyclOSM" group="Bakgrundskarta" key="backgroundmap3">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
        />
      </GroupedLayer> */}
      <GroupedLayer name="Esri - World streetmap" group="Bakgrundskarta" key="backgroundmap4">
        <TileLayer 
          attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012' 
          url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}' />
      </GroupedLayer>

       {rundor?.map((runda, index) => (
        runda.surface.title=='Asfalt' ? <GroupedLayer key={'runda'+index} checked name={runda.title} info={true} item_prop={runda} group="Landsvägsrundor">
        <Polyline
          pathOptions={{ fillColor: 'red', color: '#4d94ff' }}
          positions={runda.latlon}
        >
        <Popup key={'runda'+index}>
          {runda.title} <br /> 
          {(runda.length/1000).toFixed(1)} km <br/>
          {runda.description}
          <br/>
          <a href={runda.link}>Länk till runda på Strava</a>
        </Popup>
        </Polyline>
      </GroupedLayer>:null

            )
            )}
      {rundor?.map((runda, index) => (
        runda.surface.title=='Grus' ? <GroupedLayer key={'runda'+index} checked name={runda.title} info={true} item_prop={runda} group="Grusrundor">
        <Polyline
          pathOptions={{ fillColor: 'red', color: '#ff5f4d' }}
          positions={runda.latlon}
        >
        <Popup key={'runda'+index}>
          {runda.title} <br /> 
          {(runda.length/1000).toFixed(1)} km <br/>
          {runda.description}
          <br/>
          <a href={runda.link}>Länk till runda på Strava</a>
        </Popup>
        </Polyline>
      </GroupedLayer>:null
            )
            )} 
      
      {rundor?.map((runda, index) => (
        runda.surface.title=='MTB' ? <GroupedLayer key={'runda'+index} checked name={runda.title} info={true} item_prop={runda} group="MTB">
        <Polyline
          pathOptions={{ fillColor: 'red', color: '#453f3d' }}
          positions={runda.latlon}
        >
        <Popup key={'runda'+index}>
          {runda.title} <br /> 
          {(runda.length/1000).toFixed(1)} km <br/>
          {runda.description}
          <br/>
          <a href={runda.link}>Länk till runda på Strava</a>
        </Popup>
        </Polyline>
      </GroupedLayer>:null
            )
            )} 
      </LayerControl> 
  </MapContainer>
  );
}


