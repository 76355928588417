import { createContext, useContext, useMemo,useState  } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { fetchJsonResponse } from "../trafikverket/fetchJsonResponse";
import {getRoadIncidents}  from "../trafikverket/getRoadIncidentsQuery";
const RoadeventContext = createContext();

export const RoadeventProvider = ({ children }) => {
  const [roadevent,setRoadevent] = useLocalStorage("roadevent", null);

  const getRoadevents = async (data2) => {
    fetchJsonResponse(getRoadIncidents)
      .then((data)=>{
        setRoadevent(data['Situation']);

    }
    ).catch((err) => {
      console.log(err);
    });
    
  };


  const value = useMemo(
    () => ({
      roadevent,
      getRoadevents
    }),
    [roadevent]
  );

  return <RoadeventContext.Provider value={value}>{children}</RoadeventContext.Provider>;
};

export const useRoadevent = () => {
  return useContext(RoadeventContext);
};
