import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {baseUrl} from '../../baseUrl.js'
import Feed from './Feed'

import './InstaFeeds.css'

const InstaFeeds = ({...props}) => {
    const [feeds, setFeedsData] = useState([])

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();
        async function fetchInstagramPost () {
          try{
            axios
                .get(baseUrl+'/api/instagramtoken/')
                .then((resp)=>{
                axios
                    .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,timestamp&limit=${props.limit}&access_token=${resp.data.access_token}`)
                    .then((resp) => {
                        setFeedsData(resp.data.data)
                    })
            })
          }
        catch (err) {
              console.log('error', err)
          }}
        
        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])

    return (
        <>
        <div className="container">
            
            {feeds.map((feed) => (
                <Feed key={feed.id} feed={feed} />
            ))}
        </div>
        </>
    );
}

export default InstaFeeds;