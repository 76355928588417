import { createContext, useContext, useMemo  } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { baseUrl } from '../baseUrl.js';
const FikapassContext = createContext();

export const FikapassProvider = ({ children }) => {
  const [rundor,setRundor] = useLocalStorage("rundor", null);
  const [fik,setFik] = useLocalStorage("fik", null);
  const [kort,setKort] = useLocalStorage("kort", null);
  const [surface,setSurface] = useLocalStorage("surface", {});

  //const [auth, setAuth] = useLocalStorage("auth", null);
  //const navigate = useNavigate();

  const getRundor = async (data) => {
    fetch(baseUrl+'/api/rundor/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        //console.log(data)
        const updated_data = data.map((obj, i) => ({ ...obj, latlon: JSON.parse(obj['latlon']) }))
        setRundor(updated_data);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const getFik = async (data, token) => {
    fetch(baseUrl+'/api/fikapass/fik/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      }//,
      //body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        setFik(data);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
  };

  const getKort = async (data, token) => {
    fetch(baseUrl+'/api/fikapass/kort/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      }
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        setKort(data);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
  };

  const createKort = async (token, data) => {
    console.log('Trying to create card for user')
    fetch(baseUrl+'/api/fikapass/create_kort/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==201){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        getKort(data,token);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
  };

  const getSurface = async (data, token) => {
    fetch(baseUrl+'/api/fikapass/surface/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Authorization':'JWT '+token.slice(1,-1)
      }
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        setSurface(data);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
  };

  const logout_fikapass = () => {
    setKort(null);
    setSurface([]);
    setFik([]);
    setRundor([]);
  };
  const value = useMemo(
    () => ({
      //auth,
      fik,
      kort,
      surface,
      getRundor,
      getFik,
      getKort,
      getSurface,
      createKort,
      logout_fikapass,
      //logout,
      rundor
    }),
    [rundor,
          fik,
          kort,
          getRundor,
          getFik,
          getKort,
          getSurface,
          createKort
          ]
  );

  return <FikapassContext.Provider value={value}>{children}</FikapassContext.Provider>;
};

export const useFikapass = () => {
  return useContext(FikapassContext);
};
