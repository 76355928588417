import * as React from "react";
import {
    useParams
  } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";

function Error ({name,msg}){
    console.log(msg)
    return(
     <>
     {msg.map((m)=><li key={name}>{m}</li> )}
     
     </>)
}
export default function ConfirmPassword(){
    let { uid,token } = useParams();
    console.log(uid,token)
    const { reset, update_password } = useAuth();
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        var params= 'uid='+uid+'&token='+token+'&new_password='+data.get("new_password")+'&re_new_password='+data.get("re_new_password")

        update_password(params)
        }; 
      
    return <><Typography component="h1" variant="h5">
    Sätt nytt lösenord
  </Typography>
  {Object.entries(reset['error_msg'])?.map( ([key, value]) => <Error key = {key} name={key} msg={value}/> )}
  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
  <TextField
            margin="dense"
            required
            fullWidth
            name="new_password"
            label="Password"
            type="password"
            id="new_password"

          />
    <TextField
            margin="dense"
            required
            fullWidth
            name="re_new_password"
            label="Confirm Password"
            type="password"
            id="new_password2"
          />
    <Button
      type="submit"
      fullWidth
      sx={{ mt: 3, mb: 2,}}
      className='reg-button'
    >
      Uppdatera lösenord
    </Button>
  </Box>




  </>
}