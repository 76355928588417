import { useAuth } from "../../hooks/useAuth";

export default function GDPR() {
    const { auth } = useAuth();
    return (
        <div className="textsida">
            <h1>GDPR</h1>
            <p>Som förening är vi tvugna att samla in viss data för att exempelvis kunna sköta ett medlemsregister men också för att anordna lopp och aktiviteter</p>
            <p>Vi använder inte personuppgifter på annat sätt än för det specifika ändamål som uppgiftens sparades.</p>
            <p>Vi samlar bara in de uppgifter som är nödvändiga för uppgiften.</p>
            <p>Vi försöker uppdatera uppgifter och ger peroner möjlighet att själva uppdatera uppgifter.</p>
            <p>Vi sparar inte uppgifter längre än nödvändig vilket gör att inaktiva konton rensas med jämna mellanrum.</p>
            {auth?<><p>Om du vill att alla dina uppgifter ska försvinna från cykelintressets register skicka ett <a href="mailto:cykelintresset@gmail.com">mail</a>  </p></>:<p>När du har registrerat dig och vill rensa ditt konto så kan du närsomhelt kontakta cykelintresset för att rensa dina uppgifter genom att skicka ett <a href="mailto:cykelintresset@gmail.com">mail</a> och be om att bli borttagen från våra register .</p>}
        </div>    
        )
}
