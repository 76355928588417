
export const getRoadIncidents = `<REQUEST>
<LOGIN authenticationkey="af605949c42e47509a48c9e4cf0a92f8" />
<QUERY objecttype='Situation' schemaversion='1.2'>
<FILTER>
<WITHIN name="Deviation.Geometry.SWEREF99TM" shape="center" value="750280 7073448 " radius="50000" />
</FILTER>
</QUERY>
</REQUEST>`;

