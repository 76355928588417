import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isMobile } from 'react-device-detect';
import { baseUrl } from '../../baseUrl.js';

export default function PayFikapassFromRedirect(){
    let { uid } = useParams();
    const [swishMobileUrl, setSwishMobileUrl] = useState('');
    const [data, setData] = useState({})
    const [code, setCode] = useState( '' );
    const setandcheckcode = async (c)=>{
     if(c=='Sporren'){
       let d = data;
       d.amount = 50;
       var json_encoded = encodeURIComponent(JSON.stringify({
        "version":1,
        "payee":{
        "value":"1231974526"
        },
        "amount":{
        "value":parseInt(d.amount)
        },
        "message":{
        "value":"Fikapasset sporren "+d.payment.ocr,
        "editable":false
        }
        }));
        setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/fikapass2/'+d.id+'/&callbackresultparameter=res')
        setData(d);
     }
     setCode(c)
    }
    useEffect(() => {
      fetch(baseUrl+'/api/fikapass/'+uid+'/')
        .then(results => results.json())
        .then(data => {
          setData(data)
          var json_encoded = encodeURIComponent(JSON.stringify({
            "version":1,
            "payee":{
            "value":"1231974526"
            },
            "amount":{
            "value":parseInt(data.amount)
            },
            "message":{
            "value":"Fikapasset "+data.payment.ocr,
            "editable":false
            }
            }));
          setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/fikapass2/'+data.id+'/&callbackresultparameter=res')
        });
    }, []); // <-- Have to pass in [] here!
    isMobile=true

    return(  <><Typography component="h1" variant="h5">
                Betala fikapass
              </Typography>
               <Box component="form" onSubmit={()=>console.log('test')} noValidate sx={{ mt: 1 }}>
                  <TextField
                    size="small"
                    margin="dense"
                    value={code}
                    fullWidth
                    id="code"
                    label="Rabattkod"
                    name="rabattkod"
                    onChange={e => setandcheckcode(e.target.value)}
                  />
              </Box> 
              <Box component="form" onSubmit={()=>console.log(uid)} noValidate sx={{ mt: 1 }}>
                {isMobile?
                  <Button
                  onClick={()=>window.open(swishMobileUrl, "_blank")}
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      className='reg-button'
                  >Öppna Swish och slutför betalning</Button>:<></>
                }
              </Box>
  </>)
}