/**
 *
 * LayerControl
 *
 */

 import React, { useState } from "react";
 import { Paper, Typography, IconButton } from "@mui/material";
 import { useMapEvents } from "react-leaflet";
 import { Util } from "leaflet";
 import Accordion from "@mui/material/Accordion";
 import FormControlLabel from "@mui/material/FormControlLabel";
 import Checkbox from "@mui/material/Checkbox";
 import Button from "@mui/material/Button";
 import Box from '@mui/material/Box';
 import AccordionSummary from "@mui/material/AccordionSummary";
 import AccordionDetails from "@mui/material/AccordionDetails";
 import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
 import InfoIcon from '@mui/icons-material/Info';
 import LayersIcon from "@mui/icons-material/Layers";
 import lodashGroupBy from "lodash.groupby";
 import { LayersControlProvider } from "./layerControlContext";
 import Modal from '@mui/material/Modal';
 import createControlledLayer from "./controlledLayer";
 import './Map2.css';
 // Classes used by Leaflet to position controls
 const POSITION_CLASSES = {
   bottomleft: "leaflet-bottom leaflet-left",
   bottomright: "leaflet-bottom leaflet-right",
   topleft: "leaflet-top leaflet-left",
   topright: "leaflet-top leaflet-right"
 };

 const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
  function BasicModal({props}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button style={{'minWidth':'0px','padding':'0px'}} onClick={handleOpen}><InfoIcon/></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.description}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
 function LayerControl({ position, children }) {
   const [collapsed, setCollapsed] = useState(true);
   const [layers, setLayers] = useState([]);
   const [groups, setGroups] = useState([]);


   const positionClass =
     (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
 
   const map = useMapEvents({
     layerremove: (obj) => {
       //console.log("layer removed");
       //console.log(obj)
     },
     layeradd: () => {
       // console.log("layer add");
     },
     overlayadd: (layer, extra) => {
       // console.log(layer, extra);
     }
   });
 
   const onLayerClick = layerObj => {
     if (map?.hasLayer(layerObj.layer)) {
       map.removeLayer(layerObj.layer);
       setLayers(
         layers.map(layer => {
           if (layer.id === layerObj.id)
             return {
               ...layer,
               checked: false
             };
           return layer;
         })
       );
     } else {
       map.addLayer(layerObj.layer);
       setLayers(
         layers.map(layer => {
           if (layer.id === layerObj.id)
             return {
               ...layer,
               checked: true
             };
           return layer;
         })
       );
     }
   };
   const onLayerAllClick = layer => {
    const res = layer.map(layerObj=>Object.assign(layerObj,{checked: !layerObj.checked}))
    res.map(layerObj=>onLayerClick(layerObj))

   };
   
   const onGroupAdd = (layer, name, group, item_prop,info) => {
     setLayers(_layers => [
       ..._layers,
       {
         layer,
         group,
         name,
         item_prop,
         info,
         checked: map?.hasLayer(layer),
         id: Util.stamp(layer)
       }
     ]);
   };
   
   let tempSet = new Set();
    const ListItemsUnique = layers.filter(item => {
    if(!tempSet.has(item['name'])) {
        tempSet.add(item['name']);
        return item;
    }
    })
    
    const groupedLayers = lodashGroupBy(ListItemsUnique, "group");
    
   return (
     <LayersControlProvider
       value={{
         layers,
         addGroup: onGroupAdd
       }}
     >
       <div className={positionClass}>
         <div className="leaflet-control leaflet-bar">
           {
             <Paper
               onMouseEnter={() => setCollapsed(false)}
               onMouseLeave={() => setCollapsed(true)}
             >
               {collapsed && (
                 <IconButton>
                   <LayersIcon fontSize="default" />
                 </IconButton>
               )}
               {!collapsed &&
                 Object.keys(groupedLayers).map((section, index) => (
                   <Accordion key={`${section} ${index}`}>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                       sx={{'paddingTop':'0px','paddingBottom':'0px','minHeight':'1px','margin':'0px! important','marginBottom':'2px'}}
                     >

                       <Typography sx={{'marginTop':'2px','marginBottom':'2px'}}>{section}</Typography>

                     </AccordionSummary>
                     <FormControlLabel
                           control={
                             <Checkbox
                               checked= {false}
                               onChange={() => onLayerAllClick(groupedLayers[section])}
                               name="checkedB"
                               color="primary"
                               sx={{'paddingTop':'0px','paddingBottom':'0px'}}
                             />
                           }
                           label={'Invert Selection'}
                         />
                     {groupedLayers[section]?.map((layerObj,index) => (
                       <AccordionDetails sx={{'paddingTop':'0px','paddingBottom':'0px'}} key={layerObj.name}>
                         <FormControlLabel
                           control={<>
                             <Checkbox
                               checked={layerObj.checked}
                               onChange={() => onLayerClick(groupedLayers[section][index])}
                               name="checkedB"
                               color="primary"
                               sx={{'paddingTop':'0px','paddingBottom':'0px','paddingRight':'1px'}}
                             />
                             {layerObj.info?<BasicModal props={layerObj.item_prop}/>:<></>}
                             </>
                           }
                           label={layerObj.name}
                         />
                       </AccordionDetails>
                     ))}
                   </Accordion>
                 ))}
             </Paper>
           }
         </div>
         {children}
       </div>
     </LayersControlProvider>
   );
 }
 
 const GroupedLayer = createControlledLayer(function addGroup(
   layersControl,
   layer,
   name,
   group,
   item_prop,
   info
 ) {
   layersControl.addGroup(layer, name, group,item_prop,info);
 });
 
 export default LayerControl;
 export { GroupedLayer };
 