import React from 'react'
//Mycket kod hämtad från https://cming0721.medium.com/instagram-feeds-with-instagram-api-part-2-basic-display-api-with-react-f0c6dfcc576c

const Feed = (props) => {
    const { id, caption, media_type, media_url,timestamp} = props.feed
    let post;

    switch (media_type) {
        case "VIDEO":
            post = (
                <div style={{"verticalAlign": "top",
                "Display": "inlineBlock",
                //"TextAlign": "center",
                "Width": "120px",
                "borderStyle": "solid",
                "borderWidth": "thin",
                "borderColor": "#DDDDDD",
                "borderRadius": "2px",
                "padding":"0.6rem",
                }}>
                <video
                    width='100%'
                    height='auto' 
                    src={media_url} 
                    type="video/mp4" 
                    controls playsInline>
                </video>
                <p style ={{"display": "block","color":"#333","fontFamily": "Noto Serif, serif","fontSize":"14px"}} ><strong>{timestamp.substring(0, 10)} </strong>{caption}</p>
                </div>
            )
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <img 
                    width='100%'
                    height='auto'
                    id={id} 
                    src={media_url} 
                    alt={caption} 
                />
            );
            break;
        default:
            post = (
                <div style={{"verticalAlign": "top",
                "Display": "inlineBlock",
                //"TextAlign": "center",
                "Width": "120px",
                "borderStyle": "solid",
                "borderWidth": "thin",
                "borderColor": "#DDDDDD",
                "borderRadius": "2px",
                "padding":"0.6rem",
                }}>
                <img 
                    width='100%'
                    height='auto'
                    id={id} 
                    src={media_url} 
                    alt={caption} 
                />
                <p style ={{"display": "block","color":"#333","fontFamily": "Noto Serif, serif","fontSize":"14px"}} ><strong>{timestamp.substring(0, 10)} </strong>{caption}</p>
                </div>
            );
    }       

    return (
        <React.Fragment>
            {post}
        </React.Fragment>
    );
}

export default Feed;