import './text.css'
import Markdown from 'react-markdown'

var articles=[
    {
        "markdown": "# This is my forst article\r\n\r\n![](/media/markdownx/136f6ea5-459c-45fb-bb29-9dd2eaebfa5a.png)\r\n\r\nSome more text\r\n\r\n\r\n![](/media/markdownx/136f6ea5-459c-45fb-bb29-9dd2eaebfa5a.png)"
    }
]
export default function Article() {
    
    return (
        <Markdown children={articles[0]['markdown']}></Markdown>
        )

    };


