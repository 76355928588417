import { createContext, useContext, useMemo  } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { baseUrl } from '../baseUrl.js';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token,setToken] = useLocalStorage("token", null);
  const [auth, setAuth] = useLocalStorage("auth", null);
  const [error, setError] = useLocalStorage("error", {});
  const [profile, setProfile] = useLocalStorage("profile", {});
  const [reset, setReset] = useLocalStorage("reset",{"failed":false,"sent":false,'has_error':false,'error_msg':{}});
  const [membership, setMembership] = useLocalStorage("membership", {});
  const navigate = useNavigate();
  const register =async (data)=> {
    fetch(baseUrl+'/api/register/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        navigate("/usercreated", { replace: true });
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const put_profile =async (data,token)=> {
    fetch(baseUrl+'/api/profile/update/',{
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        get_profile(token)
        navigate("/profile", { replace: true });
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const login = async (data) => {
    return fetch(baseUrl+'/auth/jwt/create/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
          throw response;
      }).catch((err) => {
        err.json().then((err_msg)=>{
          setError({ ...error, ['loginerror']: err_msg });
      })
    })
      .then((data)=>{
        setError(current=>{ 
          const {loginerror, ...keep } =current;
          return keep
        }
          )
        setToken(data['access']);
        setAuth(true);
        get_profile(data['access']);
        get_memberships(data['access']);
        navigate("/Profile", { replace: true });
      }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const get_profile = async (token) => {
    fetch(baseUrl+'/api/profile2/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((profile)=>{
        setProfile(JSON.parse(profile)[0]['fields']);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const get_memberships = async (token) => {
    fetch(baseUrl+'/api/membership/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((membership)=>{
        
        setMembership(membership);
    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
  const reset_password = async (email) => {
    var data = 'email='+email
    const resp = fetch(baseUrl+'/auth/users/reset_password/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
        method: "POST"
      })
      .then((response) => {
        if (response.status==204){
          setReset({...reset, failed:false})
          setReset({...reset, sent:true})
          response.text();//Måste läsa annars error
          return response.ok
        }
        else if(!response.ok) 
        {
          //setReset({"failed":true,"sent":false})
          
          //response.body;
          throw response;
        }
      })
    .catch((err) => {
      console.log(err);
    });
  };
  const update_password = async (params) => {

    const resp = fetch(baseUrl+'/auth/users/reset_password_confirm/', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params,
        method: "POST"
      })
      .then((response) => {
        if (response.status==204){
          return response.ok
        }
        else if(!response.ok) 
        {
         response.json().then((json)=>{
            setReset({...reset, has_error:true})
            setReset({...reset, error_msg:json})
         })
          throw response;
        }
      })
      .then((data)=>{
        setReset({...reset, has_error:false})
        setReset({...reset, error_msg:{}})
        navigate("/register", { replace: true });
    }
    )
    .catch((err) => {
      console.log(err);
    });
  };

  const logout = () => {
    //setUser(null);
    setAuth(null);
    setToken(null);
    setMembership({});
    setProfile({});
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      auth,
      login,
      logout,
      register,
      error,
      put_profile,
      get_profile,
      profile,
      token,
      membership,
      reset,
      reset_password,
      update_password
    }),
    [auth,profile,reset,membership,token,get_profile,put_profile,login,logout,register,reset_password,update_password]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
