import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../../hooks/useAuth";
import './profile.css'
export default function Profile() {
    const { profile,
            token,
            put_profile,
            get_profile } = useAuth();

    const [edit, setEdit] = React.useState(true);
    function toggle () {
        setEdit(
         !edit
        );
        }

    const [edit_pass, setEditpass] = React.useState(true);
    function toggle_pass () {
        setEditpass(
            !edit_pass
        );
        }
    const handleSubmit2 = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        put_profile({
            firstname: data.get("firstname"),
            lastname: data.get("lastname"),
            phone: data.get("telefonnummer"),
            socialnumber: data.get("personnummer"),
            email: data.get("email"),
            co: data.get("co"),
            adress: data.get("gatuadress"),
            postalcode: data.get("postnummer"),
            city: data.get("postort")
        },token
        )
        get_profile(token)
        .then(()=>setEdit(
            !edit
           ));
        };

    if(Object.values(profile).every(x => x === null || x === '')){
        return(<div></div>)
    }
    return (
<div className="textsida">
  <h1>Min Profil</h1>
    <Typography component="h1" variant="h5">
        Mina kontaktuppgifter
    </Typography>
    <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 1 }}>
        <TextField
            disabled={edit}
            size="small"
            margin="dense"
            defaultValue={profile.firstname}
            required
            name="firstname"
            label="firstname"
            id="firstname"
            sx={{ width:'45%',paddingRight:'5%' }}
          />
          <TextField
            disabled={edit}
            size="small"
            margin="dense"
            defaultValue={profile.lastname}
            required
            name="lastname"
            label="lastname"
            id="lastname"
            sx={{ width:'50%' }}
          />
        <TextField
            disabled={edit}
            defaultValue={profile.phone}
            size="small"
            margin="dense"
            required
            fullWidth
            name="telefonnummer"
            label="Telefonnummer"
            id="telefonnnummer"
        />
        <TextField
            disabled={edit}
            defaultValue={profile.email}
            size="small"
            margin="dense"
            required
            fullWidth
            name="email"
            label="E-mail"
            id="email"
        />
        <TextField
            disabled={edit}
            defaultValue={profile.socialnumber}
            size="small"
            margin="dense"
            required
            fullWidth
            name="personnummer"
            label="Personnummer"
            id="persnr"
        />
        <TextField
            disabled={edit}
            defaultValue={profile.co}
            size="small"
            margin="dense"
            name="co"
            label="c/o"
            id="co"
        />
        <TextField
            disabled={edit}
            defaultValue={profile.adress}
            size="small"
            margin="dense"
            required
            fullWidth
            name="gatuadress"
            label="Gatuadress"
            id="gata"
        />
        <TextField
            disabled={edit}
            defaultValue={profile.postalcode}
            size="small"
            required
            margin="dense"
            name="postnummer"
            label="Postnummer"
            id="postnr"
            sx={{paddingRight:'20px'}}
            />
        <TextField
            disabled={edit}
            defaultValue={profile.city}
            size="small"
            margin="dense"
            required
            name="postort"
            label="Postort"
            id="postort"
        />
        {edit==false?<>
        <Button
            onClick={toggle}
            //type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
        >
        Avbryt 
        </Button><Button
            //onClick={()=>console.log('Uppdatera')}
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
        >
        Uppdatera uppgifter 
        </Button>
        </>:
        <Button
        onClick={toggle}
        //type="submit"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
    >
    Redigera uppgifter 
    </Button>}
    </Box>

    {/* <Typography component="h1" variant="h5">
        Lösenord
    </Typography>
    <Box component="form" onSubmit={console.log('Skapa endpoint för ändra lösenord')} noValidate sx={{ mt: 1 }}>
        <TextField
            disabled={edit_pass}
            size="small"
            margin="dense"
            required
            fullWidth
            name="oldpassword"
            label="Old password"
            type="password"
            id="oldpassword"
        />
        <TextField
            disabled={edit_pass}
            size="small"
            margin="dense"
            required
            name="newpassword2"
            label="New Password"
            type="password"
            id="newpassword"
            sx={{paddingRight:'20px'}}
        />
        <TextField
            disabled={edit_pass}
            size="small"
            margin="dense"
            required
            name="newpassword2"
            label="Confirm Password"
            type="password"
            id="newpassword2"
        />
        {edit_pass==false?<>
        <Button
            onClick={toggle_pass}
            //type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
        >
        Avbryt 
        </Button><Button
            onClick={()=>console.log('uppdatera')}
            //type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
        >
        Uppdatera uppgifter 
        </Button>
        </>:
        <Button
        onClick={toggle_pass}
        //type="submit"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        style={{'backgroundColor':'#333333',color:'#ffffff',fontFamily: "Noto-Serif ",fontWeight:"bold"}}
    >
    Redigera uppgifter 
    </Button>}
    </Box> */}
</div>
    );
  }
