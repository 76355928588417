import './text.css'
import React,{useEffect, useState} from 'react';
import { useLocation,useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import { baseUrl } from '../baseUrl.js';
//const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.109:8000' : 'https://cykelintresset.pythonanywhere.com'

export default function General() {
    let slug = useParams();
    const [markdown, setMarkdown] = useState(null);

    const displayslug = slug.slug
    useEffect(() => {
        fetch(baseUrl+'/api/cms/page/'+displayslug+'/')
          .then(results => results.json())
          .then(data => {
            setMarkdown(data.markdown);
          });
      }, [slug]); // <-- Have to pass in [] here!
    return (
<div className="textsida">
  <Markdown children={markdown}></Markdown>
</div>
    );
  }
