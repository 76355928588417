import { createContext, useContext, useMemo  } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { baseUrl } from '../baseUrl.js';
const CMSContext = createContext();

export const CMSProvider = ({ children }) => {
  const [mainMenu,setMainMenu] = useLocalStorage("mainMenu", null);
  const [userMenu,setUserMenu] = useLocalStorage("userMenu", null);

  const getMenus = async () => {
    fetch(baseUrl+'/api/cms/menusbytype/',{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      })
      .then((response) => {
        if (response.status==200){
          return response.json();
        }
        else if(!response.ok) 
        {
          throw response;
        }
      })
      .then((data)=>{
        
        data.MAIN = data.MAIN.map(function( obj ) {
            if (!Array.isArray(obj['submenu']) || !obj['submenu'].length) {
                delete obj.submenu
              }
            return obj;
          });
          data.PROFILE = data.PROFILE.map(function( obj ) {
            if (!Array.isArray(obj['submenu']) || !obj['submenu'].length) {
                delete obj.submenu
              }

            return obj;
          });
        setMainMenu(data['MAIN']);
        setUserMenu(data['PROFILE']);

    }
    ).catch((err) => {
      //TODO: catch response and update loginpage with error.
      console.log(err);
    });
    
  };
//   const getFik = async (data, token) => {
//     const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.109:8000' : 'https://cykelintresset.pythonanywhere.com'
//     console.log(token)
//     fetch(baseUrl+'/api/CMS/fik/',{
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':'Bearer '+token.slice(1,-1)
//       }//,
//       //body: JSON.stringify(data),
//       })
//       .then((response) => {
//         if (response.status==200){
//           return response.json();
//         }
//         else if(!response.ok) 
//         {
//           throw response;
//         }
//       })
//       .then((data)=>{
//         setFik(data);
//     }
//     ).catch((err) => {
//       //TODO: catch response and update loginpage with error.
//       console.log(err);
//     });
//   };

//   const getKort = async (data, token) => {
//     const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.109:8000' : 'https://cykelintresset.pythonanywhere.com'
//     console.log(token)
//     fetch(baseUrl+'/api/CMS/kort/',{
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization':'Bearer '+token.slice(1,-1)
//       }
//       })
//       .then((response) => {
//         if (response.status==200){
//           return response.json();
//         }
//         else if(!response.ok) 
//         {
//           throw response;
//         }
//       })
//       .then((data)=>{
//         setKort(data);
//     }
//     ).catch((err) => {
//       //TODO: catch response and update loginpage with error.
//       console.log(err);
//     });
//   };

  const value = useMemo(
    () => ({

      userMenu,
      mainMenu,
      getMenus,

    }),
    [
    userMenu,
    mainMenu,
    ]
  );

  return <CMSContext.Provider value={value}>{children}</CMSContext.Provider>;
};

export const useCMS = () => {
  return useContext(CMSContext);
};
