import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  BrowserRouter,
  Routes,
  Route, } from "react-router-dom";
import About from "./routes/about";
import General from "./routes/general_page";
import {RegisterLogin} from "./routes/register";
import Arrangemang from "./routes/arrangemang";
import Profile from "./routes/user_routes/profile";
import ForgotPass from "./routes/user_routes/forgot_pass";
import ConfirmPassword from "./routes/user_routes/confirm_password";
import Membership from "./routes/user_routes/membership";
import {FikapassTabs} from "./routes/fikapass";
import Membership2 from "./routes/user_routes/membership2";
import GDPR from "./routes/user_routes/gdpr";
import PayMembershipFromRedirect from "./routes/user_routes/payfromredirect";
import PayFikapassFromRedirect from "./routes/user_routes/payfromredirectfikapass";
import Article from "./routes/article";
import Map2 from "./routes/Karta/Map2";
import {FailedPayment,AlreadyPaid,PaymentSucceded} from "./routes/user_routes/payment";
import Collaborations from "./routes/collaborations";
import InstaPage from "./routes/InstagramFeed/InstaPage";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./hooks/useAuth";
import { RoadeventProvider } from "./hooks/useRoadevents";
import { FikapassProvider } from "./hooks/useFikapass";
import { CMSProvider } from './hooks/cms';
import DetailedMap from './routes/Karta/DetailedMap';
import NewsDetail from './routes/general_news';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <CMSProvider>
    <AuthProvider>
    <RoadeventProvider>
    <FikapassProvider>
      <Routes>
        <Route path="/" element={<App />} >
          <Route index element={<InstaPage />} />
          {/* <Route path="about" element={<General />}/> */}
          <Route path=":slug/" element={<General />}/>
          <Route path="register" element={<RegisterLogin />}/>
          <Route path="forgot_pass" element={<ForgotPass />}/>
          <Route path="reset_password/:uid/:token" element={<ConfirmPassword />}/>
          {/* <Route path="arrangemang" element={<Arrangemang />}/> */}
          <Route path="samarbeten" element={<Collaborations />}/>
          <Route path="profile" element={<Profile />}/>
          <Route path="gdpr" element={<GDPR />}/>
          <Route path="fikapasset" element={<FikapassTabs />}/>
          <Route path="detailedmap" element={<DetailedMap />}/>
          <Route path="fikapass" element={<FikapassTabs />}/>
          {/* <Route path="medlemskap" element={<Membership />}/> */}
          <Route path="medlemskap" element={<Membership2 />}/>
          <Route path="betalamedlemskap/:uid/" element={<PayMembershipFromRedirect />}/>
          <Route path="betalafikapass/:uid/" element={<PayFikapassFromRedirect />}/>
          <Route path="failedpayment" element={<FailedPayment />}/>
          <Route path="alreadypaid" element={<AlreadyPaid />}/>
          <Route path="paymentsucceded" element={<PaymentSucceded />}/>
          <Route path="article" element={<Article />}/>
          <Route path="news/:id/" element={<NewsDetail />}/>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
      </FikapassProvider>
      </RoadeventProvider>
    </AuthProvider>
    </CMSProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
