import React, { Component, useState,useEffect } from "react";
import {  Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { render } from "react-dom";
import { useMyPosition } from "../../hooks/useMyPosition";
//import geolocation from 'geolocation'
//const position2 = [63.833, 20.28]

var greenIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  function createIcon(url) {
    return new L.Icon({
      iconUrl: url,
      iconSize: [30, 30]
    });
  }
  function getCyclistMarkerIcon(index) {
    return createIcon("https://www.seekpng.com/png/full/272-2721063_cycling-cyclist-png-biking-icon.png");
    }



export default function Tracker(){

  const {latitude, longitude, error} = useMyPosition(true);
  if (!latitude){
    return( 

    <Marker position={[0, 0]} icon= {getCyclistMarkerIcon(1)} >
    <Popup>
      Din position.
    </Popup>
  </Marker>
  )}
  else if (latitude){
    
    return( 

        <Marker position={[latitude,longitude]} icon= {getCyclistMarkerIcon(1)} >
        <Popup>
          Din position.
        </Popup>
      </Marker>
  )
  }}