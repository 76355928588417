import './text.css'
import React,{useEffect, useState} from 'react';
import Markdown from 'react-markdown'
import { baseUrl } from '../baseUrl.js';
export default function NewsDetail() {
    const [collaborations, setCollaborations] = useState(null);

    useEffect(() => {
        fetch(`${baseUrl}/api/cms/collaborations/`)
          .then(results => results.json())
            .then(data => {
                console.log(data)
                setCollaborations(data);
            });
      }, []); // <-- Have to pass in [] here!
    return (
      <div className="textsida">
        {collaborations?.map(collaboration=>{
            return <>
            <Markdown children={collaboration.markdown_long}></Markdown>
            <hr/>
            </>
        })}
      </div>
    );
  }

