import InstaFeeds from './InstaFeeds'
import NewsFeed from './NewsFeed'
const InstaPage = () => {
    
    return (
        <div className="wrapper">
          <div className="content">
            <h3 style={{'marginLeft':'1em'}}>Från oss i sociala medier</h3>
            <InstaFeeds  limit={12}/>
          </div>
          <div className="side">
            <h3 style={{'marginLeft':'1em'}}>Nyheter</h3>
            <NewsFeed  limit={12}/>
          </div>
        </div>

    );
}

export default InstaPage;