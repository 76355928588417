
export function FailedPayment() {

    return (
        <div>
            <h1>
                Betalning Misslyckades
            </h1>
        </div>
    )
}
export function AlreadyPaid() {

    return (
        <div>
            <h1>
                Finns redan en betalning för detta år.
            </h1>
        </div>
    )
}
export function PaymentSucceded() {

    return (
        <div>
            <h1>
                Betalning genomförd.
            </h1>
            <p>Logga ut och logga in igen så uppdateras all nödvändig information.</p>
        </div>
    )
}