import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFikapass } from "../hooks/useFikapass";
import { useAuth } from "../hooks/useAuth";
import { isMobile } from 'react-device-detect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import './register.css';
import './fikapass.css'
import './text.css'
import Map2 from './Karta/Map2'
import { initate_fikapass_desktop2, getqrimage } from "../hooks/fikapassApi";

const modalStyle = {
  position:'absolute',
  left:'50%',
  top:'50%',
  transform: 'translate(-50%, -50%)',
  maxHeight:'90%',
  width: '50%',
  backgroundColor: 'white',
  boxShadow: 24,
  margin:'auto',
  border: 'black',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '15px',
  padding:'10px'
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function dateafter(){
  var after = false;
  var d = new Date(new Date().getFullYear(), 3, 1);
  var now =new Date()
  after = now>d
  //console.log(after,now,d)
  return after
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns= [
  { id: 'fik', label: '#', minWidth: 10 },
  { id: 'detaljer', label: 'Detaljer', minWidth: 15 },
  { id: 'fik_name', label: 'Name', minWidth: 190 },
  { id: 'besökt', label: 'Besökt', minWidth: 70, align: 'right',
  }
];

const RundorTable = ({data}) =>  {
  //console.log(data)
  const runda_columns= [
    { id: 'id', label: '#', minWidth: 5 },
    { id: 'runda', label: 'Namn', minWidth: 100 },
    { id: 'length', label: 'Distans', minWidth: 20 },
    { id: 'Underlag', label: 'Underlag', minWidth: 40, align: 'left'}
  ];
return(<TableContainer sx={{ maxHeight: 540 }}>
  <Table className="styled-table">
    <TableHead>
      <TableRow>
        {runda_columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ top: 10, minWidth: column.minWidth }}
          >
            {column.label}
          </TableCell>
        ))} 
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row,idx) => {
        return(
          <TableRow key={idx}>
            <TableCell style={{width: '5%'}}>
               {row.id} 
            </TableCell>
            <TableCell style={{width: '40%'}}>
              {row.title} 
            </TableCell>
            <TableCell style={{width: '20%'}}>
            {(row.length/1000).toFixed(1)} km 
            </TableCell>
            <TableCell style={{width: '20%'}}>
            {row.surface?.title} 
            </TableCell>
          </TableRow>
        )
        })}
                  </TableBody>
        </Table>
      </TableContainer>
)}

const FikTable = ({data}) =>  {
  //console.log(data)
    const [modalOpen, setModalOpen] = React.useState(false);
  const [activerunda, setActiverunda] = React.useState({});
  const handleClose = () => setModalOpen(false);
  const openModal = (data)=>{
    //onsole.log(data)
    setActiverunda(data);
    setModalOpen(true);
  }
  
  const runda_columns= [
    { id: 'id', label: '#', minWidth: 5 },
    { id: 'details', label: 'Detaljer', minWidth: 5 },
    
    { id: 'Fik', label: 'Namn', minWidth: 100 },
  ];
  
return(<TableContainer sx={{ maxHeight: 440 }}>
  <Table className="styled-table">
    <TableHead>
      <TableRow>
        {runda_columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ top: 10, minWidth: column.minWidth }}
          >
            {column.label}
          </TableCell>
        ))} 
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row,idx) => {
        return(
          <TableRow key={idx}>
            <TableCell style={{width: '5%'}}>
               {row.fiket.id} 
            </TableCell>
            <TableCell style={{width: '5%'}}>
                    <Button onClick={()=>openModal(row.fiket)} sx={{color:'#999', lineHeigh:'inherit',padding:'0px'}}>INFO</Button>
                    
                  </TableCell>
            <TableCell style={{width: '40%'}}>
              {row.fiket.namn}
            </TableCell>
          </TableRow>
        )
        })}
        {Object.keys(activerunda).length !== 0?<Modal disableEnforceFocus
              BackdropProps={{style: {backgroundColor: 'white',borderRadius:'15px'}}}
                    sx={modalStyle}
                    open={modalOpen}
                    onClose={handleClose}
                    >
                    <Box>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                            color: 'black',
                            }}
                        >
                          <CloseIcon />
                        </IconButton>
                    <h5>{activerunda?.namn}</h5>
                    <p>{activerunda?.description}</p>
                    </Box>
                </Modal>:<></>}
                  </TableBody>
        </Table>
      </TableContainer>
)}
export const Info = (props) =>  {
  const { children, value, index, ...other } = props;
  return (
      <div role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <div style={{'paddingLeft':'1em','paddingRight':'1em','height':'83vh','overflow': 'scroll'}}>
        <p>Först och främst. Kul att du skaffat fikapasset. Vi som står bakom fikapasset hoppas att det kan inspirera dig att komma ut på cykeln lite mer, se lite andra vägar och hjälpa dig och dina vänner att komma ut på trevliga cykelrundor. För cykling liksom allt annat blir mycket roligare om man gör det tillsammans.</p>
        <p>Vi har gjort vårt bästa i att hitta så många fik som möjligt. Några är större etablerade medan några är mindre. Vissa passar bra som glasstopp medan andra lämpar sig för lunch. Några är utvalda för att det är intressant miljö eller historiskt intressant område. Såklart har vi säkert missat något fik. Hör gärna av dig så kan vi lägga till under säsongen. </p>
        <p>Rundorna som finns är ett litet urval av alla möjliga kombinationer som finns. Några rundor saknas även men vi hoppas kunna lägga till några lite längre rundor snart.</p>
        <p>För att underlätta ruttplanering så hämtas vägarbeten från trafikverket. Alla vägarbeten är inte så omfattande utan det går att passera. Så låt dig inte helt avskräckas av en runda bara för att du ser en vägarbetssymbol. Men klicka gärna på den och läs hur mycket det påverkar framkomlighet. Att cykla flera kilometer på bärlagret är ingen njutning medan dikesklippning inte är något större hinder.</p>
        <p>Sidan är framtagen av ideella krafter och det finns massor med förbättringspotential. Vårt mål är att sidan ska funka som hjälmedel på turen och innan alltså både på dator och på telefonen. Önskar du några större eller mindre förändringar av layout eller användarupplevelse så skicka gärna feedback. Vårt mål är att sidan ska bli bättre eftersom. Vårt primära mål är att den ska vara funktionell.</p>
        <p>Det kan vara en god idé att logga in och ut någon gång ibland. Då uppdateras dina inchekningar och nya trafikvarningar hämtas.</p>
        <p>Tycker du fikapasset är en kul idé. Tagga gärna fikapasset och sprid ordet i sociala medier. Ju fler som använder det desto roligare blir det att utcekla konceptet och sidan.</p>
        <h4>Vanliga frågor</h4>
        <p><span STYLE="font-weight:bold">Jag glömde checka in? - </span>Vi kan lägga in det i efterhand. Skicka ett mail till cykelintresset@gmail.com med bevis på att du varit på fiket. Foto, kvitto eller rutt på strava eller annan träningslogg funkar. Andra kreativa lösningar kan också funka.</p>
        <p><span STYLE="font-weight:bold">Måste jag fika? - </span>Kontrollerna där man checkar in ligger vid fik för att fiken är naturliga träffpunkter för cyklister. Man måste inte stanna på dem och fika, men man måste vara i närheten av fiket för att kunna checka in.</p>
        <p><span STYLE="font-weight:bold">Kan jag ge bort fikapasset? - </span>Ja! Skapa ett konto logga in och betala sedan kan du ge bort inlogg och lösen. </p>
        <p><span STYLE="font-weight:bold">Finns det en fysisk karta? - </span>I år har vi valt att helt gå över till en digital tjänst. Finns intresse kanske vi återinför fusiska kartan som komplement.</p>
        <p><span STYLE="font-weight:bold">När sker utlottning? - </span> Utlottningen sker i september. Sommaren kommer då vara över men det kan vara riktigt fin cykling även tidig höst. </p>
        
        <p>Sist men inte minst! Hoppas ni får en riktigt trevlig cykelsommar. Kanske ses vi ute på någon runda eller på en gemensam fikatur.</p>
      </div>
      </div>
  );
}


export const Fikapass = (props) =>  {
  const { children, value, index, ...other } = props;
  const { kort,fik } = useFikapass();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activefik, setActivefik] = React.useState({});

  const handleClose = () => setModalOpen(false);

  var merged = [];
  //if (kort.lengt==undefined){
  //  return <></>
  //}
  for(let i=0; i<fik[0].kontroller.length; i++) {
    merged.push({
    ...fik[0].kontroller[i], 
   ...(kort[0].incheckning.find((itmInner) => itmInner.fik_name === fik[0].kontroller[i].fiket.namn))
  }
    );
  }
  const openModal = (data)=>{
    //onsole.log(data)
    setActivefik(data);
    setModalOpen(true);
  }

  
  return (
      <div role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <TableContainer sx={{ maxHeight: '83vh' }}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 10, minWidth: column.minWidth }}
                  
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {merged.map((row,idx) => {
              
              return(
                <TableRow key={idx}>

                  <TableCell style={{width: '3%'}}>
                    {row.fiket.id}
                  </TableCell>
                  <TableCell style={{width: '5%'}}>
                    <Button onClick={()=>openModal(row)} sx={{color:'#999', lineHeigh:'inherit',padding:'0px'}}>INFO</Button>
                    
                  </TableCell>
                  <TableCell style={{width: '20%'}}>
                    {row.fiket.namn}
                  </TableCell>
                  <TableCell>
                    {row.date}
                  </TableCell>
                </TableRow>
              )
              })}
              {Object.keys(activefik).length !== 0?<Modal disableEnforceFocus
                    open={modalOpen}
                    onClose={handleClose}
                    >
                    <Box sx={modalStyle}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                            color: 'black',
                            }}
                        >
                          <CloseIcon />
                        </IconButton>
                    <h5>{activefik?.fiket.namn}</h5>
                    <p>{activefik?.fiket.description}</p>
                    <h6>Alternativa rundor</h6>
                    <RundorTable data={activefik.rundor}></RundorTable>
                    </Box>
                </Modal>:<></>}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  );
}

function valuetext2(value2) {
  return `${value2}°C`;
}

export const Rundor = (props) =>  {
  const { children, value, index, ...other } = props;
  const { rundor, surface } = useFikapass();
  const [value2, setValue2] = React.useState([20, 100]);
  const [visible, setVisible] = React.useState([...surface]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activerunda, setActiverunda] = React.useState({});

  const handleClose = () => setModalOpen(false);
  const handleChange = (event, newValue2) => {
    setValue2(newValue2);
  };
  const handleChange2= (surf) => {

    var index = visible.indexOf(surf)

    if (index>-1){

      var arr = visible.filter(item => item !== surf)
      setVisible(
        arr
      )
  }
  else{
    setVisible(visible=>[...visible,surf])
  }
  };
  const columns= [
    // { id: 'id', label: '#', minWidth: 3 },
    { id: 'link', label: 'Länk', width: '40px' },
    { id: 'info', label: 'Info', width: '10px' },
    { id: 'name', label: 'Namn', width: '170px' },
    { id: 'distance', label: 'km', minWidth: 50 },
    { id: 'surface', label: 'typ', minWidth: 30, align: 'right'},
  ];

  var surface_names = visible.map((vis)=>vis.title) 
  const passToMap =(runda)=>{
    navigate("/detailedmap", { state:runda })
  };
  const openModal = (data)=>{
    //onsole.log(data)
    setActiverunda(data);
    setModalOpen(true);
  }
  return (
      <div role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>


      <FormGroup sx={{flexDirection:'column'}}>
        <Box sx={{flexDirection:'row'}}>
          {surface?.map((surf,index) => <FormControlLabel key = {index} sx={{color:'#333'}} control={<Checkbox defaultChecked onClick={()=>handleChange2(surf)} sx={{color:'#333 !important'}}/>} label={surf.title} />)}
        </Box>
        <>
        <FormControlLabel sx={{color:'#333', marginLeft:'10px'}} label="Distans" control={<Slider
        getAriaLabel={() => "Temperature range"}
        value={value2}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext2}
        max={300}
        min={10}
        sx={{width:'30%',color:'#333',marginRight:'10px'}}
      />}  />
        </>

      </FormGroup>
      <TableContainer style={{minWidth:'100%',maxWidth:'100%',height:'75vh'}}>
        <Table className="styled-table"  >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 10, width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))} 
            </TableRow>
          </TableHead>
          <TableBody>
            {rundor.map((row,idx) => {
              return(
                (row.length>value2[0]*1000 &row.length<value2[1]*1000 & surface_names.includes(row.surface?.title))?<TableRow key={'runda-'+idx}>
                  <TableCell style={{padding:'1px'}}>
                     <Button onClick={()=>passToMap(row)} sx={{color:'#999', lineHeigh:'inherit',padding:'0px',fontSize:'12px'}} style={{maxWidth: '40px', maxHeight: '30px', minWidth: '40px', minHeight: '30px'}}>Karta</Button>
                  </TableCell>
                  <TableCell sx={{width:'20px',paddin:'0px'}} style={{padding:'1px'}}>
                    <Button onClick={()=>openModal(row)} sx={{color:'#999', lineHeigh:'inherit',padding:'0px'}} style={{maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'}}><InfoIcon/></Button>
                  </TableCell>
                  <TableCell style={{padding:'1px'}}>
                    {row.title}
                  </TableCell>
                  <TableCell style={{padding:'1px'}}>
                    {(row.length/1000).toFixed(1)}
                  </TableCell>
                  <TableCell style={{padding:'1px'}}>
                    {row.surface?.title}
                  </TableCell>
                </TableRow>:<></>
              )
              })}
              {Object.keys(activerunda).length !== 0?<Modal disableEnforceFocus
              BackdropProps={{style: {backgroundColor: 'white',borderRadius:'15px'}}}
                    sx={modalStyle}
                    open={modalOpen}
                    onClose={handleClose}
                    >
                    <Box>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                            color: 'black',
                            }}
                        >
                          <CloseIcon />
                        </IconButton>
                    <h5>{activerunda?.title}</h5>
                    <p>{activerunda?.description}</p>
                    <h6>Fik på rundan</h6> 
                      <FikTable data={activerunda.kontroller}></FikTable>
                    </Box>
                </Modal>:<></>}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  );
}
export const Karta = (props) =>  {
  const { children, value, index, ...other } = props;
  
  return (
      <div role="tabpanel"
           hidden={value !== index}
           id={`simple-tabpanel-${index}`}
           aria-labelledby={`simple-tab-${index}`}
           {...other}
           style={{width:'100%',height:'100%'}}>
        
          <Map2 />
      </div>
  );
}
const BuyFikapass= ()=>{
  const [img, setImg] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [swishMobileUrl, setSwishMobileUrl] = useState('');
  const handleClose = () => setModalOpen(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const [code, setCode] = useState( '' );
  //TODO: fikapassID borde tas bort är väl null?
  const [fikapassID] = React.useState(0);
 //TODO: amount och setAmount borde tas bort är väl null?
  const [amount, setAmount] = React.useState(100);
  const swishPay_desktop2 = async (token,amount,fikapassID)=>{
    const swishtoken = await initate_fikapass_desktop2(token,fikapassID,amount);
    const res = await getqrimage(token,JSON.parse(swishtoken).swish_token);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
    setModalOpen(true);
}
//Funkar i chrome på ios och safari ios
const swishPay_mobile2 = async (token,amount,fikapassID)=>{
    const fikapass_info = await initate_fikapass_desktop2(token,amount);

    var fikapass_parsed = JSON.parse(fikapass_info);
    if ('redirect' in fikapass_parsed){
        navigate("/alreadypaid", { replace: true });
    }
    var message = "Fikapass "+fikapass_parsed.ocr
    if(code=='Sporren'){
      fikapass_parsed.amount=50
      var message = "Fikapass sporren "+fikapass_parsed.ocr
    }
    var json_encoded = await encodeURIComponent(JSON.stringify({
        "version":1,
        "payee":{
        "value":"1231974526"
        },
        "amount":{
        "value":parseInt(fikapass_parsed.amount)
        },
        "message":{
        "value":message,
        "editable":false
        }
        }));
    
    setSwishMobileUrl('swish://payment?data='+json_encoded+'&callbackurl=https://www.cykelintresset.se/api/fikapass2/'+fikapass_parsed.kort_id+'/&callbackresultparameter=res')
    
    setModalOpen(true);
  };
  const image1 = require('../media/fikapasset-example.png')
  const image2 = require('../media/fikapasset-example2-rundor.png')
  const image3 = require('../media/fikapasset-example3-fik.png')

  return (<div className="textsida" margin="auto">
    <h1>Fikapasset</h1>
    <p>Fikapasset är ett hjälpmedel som förhoppningsvis kan hjälpa både erfarna och 
       nya cyklister i umeå med omnejd att hitta inspiration vid val av runda för dagen. </p>
    <p>Fikapasset finns som i både digital och analog form. Den digital erbjuder endel möjligheter som pappersvarianten inte har. Några av fördelara med den digitala variantern är:</p>
    <li>Navigeringshjälpmedel ute på rundan.</li>
    <li>Aktiva och planerade vägarbeten presenteras mha Trafikverkets öppna data.</li>
    <li>Incheckning på kontrollerna sker på plats vid fiket och man slipper skicka in sina "stämplade" kartor.</li>
    <li>Det blir lättare att lägga och uppdatera information kring rundor och fik under pågående säsongen.</li>
    <li>Mer information går att presentera jämfört med ett kartblad.</li>

    <p>För varje stämpling vid en kontroll erhåller du en lott </p>
    <p>Är du intresserad av att köpa ett fikapass så är priset iår 100kr.</p>
    <div>
      <img width='100%' alt='profile' src={image1} />
      <figcaption class="caption">Bildexempel på kartvyn tillsammans med två rundor, några fik samt planerade vägarbeten under året.</figcaption>
    </div>
    <div>    
      <img width='100%' alt='profile' src={image3} />
      <figcaption class="caption">Fikapasset med incheckningsdatum som visas i Besöktkolumnen.</figcaption>
    </div>

    <hr></hr>
    <Box component="form" onSubmit={()=>console.log('test')} noValidate sx={{ mt: 1 }}>
          <TextField
            size="small"
            margin="dense"
            value={code}
            fullWidth
            id="code"
            label="Rabattkod"
            name="rabattkod"
            onChange={e => setCode(e.target.value)}
          />
      </Box>
    {(token!=null&dateafter())?
    <div style={{'marginLeft':'50%','transform': 'translate(-50%, 0%)'}}>
      {isMobile?
                    <Button
                        onClick={()=>swishPay_mobile2(token,amount,fikapassID)}
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        className='reg-button'
                    >Öppna Swish och slutför betalning</Button>:<Button
                    onClick={()=>swishPay_desktop2(token,amount,fikapassID)}
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    className='reg-button'
                >Skapa QR-kod</Button>
                }
      </div>:<>
      <h2>Så skaffar du fikapasset</h2>
      <p>Skapa först en användare här på sidan. Därefter kan du logga in köpa ett fikapass.</p>
      <p>Har du redan en användare så logga först in.</p>
      <p>Vi hoppas kunna börja sälja fikapasset 1:a maj 2023.</p>
      </>}
      <Modal disableEnforceFocus
      BackdropProps={{style: {backgroundColor: 'white',borderRadius:'15px'}}}
                    sx={modalStyle}
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >

                    {isMobile?
                    <Box align="left">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                        position: 'relative',
                        right: 8,
                        top: 0,
                        color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>Fortsätt till betalning genom att öppna swish.</Typography>
                        <Button
                            onClick={()=>window.open(swishMobileUrl, "_blank")}
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            className='reg-button'
                        >Öppna swish appen</Button>
                    </Box>:
                    <Box align="center">
                        <img src={img} 
                             data='image/jpeg;base64' 
                             alt="icons" 
                             style={{'width':'200px',paddingTop:'10px'}}/>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Fullfölj betalningen genom att scanna qrkoden med en enhet som har swish. Och följ anvisningar på den enheten.
                        </Typography>
                        <Button
                            onClick={()=>setModalOpen(false)}
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            className='reg-button'
                        >Avbryt betalning</Button>
                    </Box>
                    }
                </Modal>
  </div>)
}
export const FikapassTabs = () => {
  const [value, setValue] = React.useState(0);
  const { kort } = useFikapass();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!kort?.length){
    return (<BuyFikapass />)
  }
  return (
    <Container component="main"  style={{'maxWidth':'100%','padding':'0px'}}>   
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth:'100%'
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' ,maxWidth:'100%'}}>
          <Tabs value={value} onChange={handleChange} style={{minHeight:'32px'}}>
            <Tab label="Fik" {...a11yProps(0)} style={{paddingLeft:'1px',paddingTop:'2px',paddingBottom:'2px',paddingRight:'1px',minHeight:'22px',paddingTop:'6px',paddingBottom:'6px'}}/>
            <Tab label="Rundor" {...a11yProps(1)} style={{paddingLeft:'1px',paddingTop:'2px',paddingBottom:'2px',paddingRight:'1px',minHeight:'22px',paddingTop:'6px',paddingBottom:'6px'}}/>
            {/* <Tab label="Sevärdheter" {...a11yProps(1)} style={{paddingLeft:'1px',paddingRight:'1px',minHeight:'32px'}}/> */}
            <Tab label="Karta" {...a11yProps(1)} style={{paddingLeft:'1px',paddingTop:'2px',paddingBottom:'2px',paddingRight:'1px',minHeight:'22px',paddingTop:'6px',paddingBottom:'6px'}}/>
            <Tab label="Info" {...a11yProps(1)} style={{paddingLeft:'1px',paddingTop:'2px',paddingBottom:'2px',paddingRight:'1px',minHeight:'22px',paddingTop:'6px',paddingBottom:'6px'}}/>
          </Tabs>
        </Box>
        <Fikapass value={value} index={0}></Fikapass>
        <Rundor key='123' value={value} index={1}></Rundor>
        {/* <Rundor key='456' value={value} index={2}></Rundor> */}
        <Karta value={value} index={2}></Karta> 
        <Info value={value} index={3}></Info> 
      </Box>
    </Container>
  );
};
