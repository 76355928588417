import './text.css'
import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import { baseUrl } from '../baseUrl.js';
export default function NewsDetail() {
    let { id } = useParams();
    const [news, setNews] = useState(null);

    useEffect(() => {
        fetch(`${baseUrl}/api/cms/news/${id}/`)
          .then(results => results.json())
            .then(data => {
              setNews(data.markdown);
            });
      }, []); // <-- Have to pass in [] here!
    return (
      <div className="textsida">
        <Markdown children={news}></Markdown>
      </div>
    );
  }
