import React, { useRef } from "react";
import { MapContainer, TileLayer, Marker, Circle, Popup,Polyline,LayersControl,LayerGroup,  FeatureGroup,
} from 'react-leaflet';

import { useLocation,Link } from 'react-router-dom';
import LayerControl, { GroupedLayer } from "./LayerControl";


import L from "leaflet";
import Tracker from './Tracker';

import Button from '@mui/material/Button';

import { useRoadevent } from "../../hooks/useRoadevents";
import { useMyPosition } from "../../hooks/useMyPosition";
import { useFikapass } from "../../hooks/useFikapass";
import 'leaflet/dist/leaflet.css';
import './Map2.css';
import roadworkIcon from "./img/roadwork.png"
import coffeeIcon from "./img/coffe.png"
import { useAuth } from "../../hooks/useAuth";
import {checkin} from "../../hooks/fikapassApi"
const position = [63.833, 20.28];

function createIcon(url) {
  return new L.Icon({
    iconUrl: url,
    iconSize: [30, 30]
  });
}
// uppdatera kartstorleken så att den är återanvändingsbar
// https://github.com/PaulLeCam/react-leaflet/issues/340
const resizeMap = (mapRef) => {
  const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize())
  const container = document.getElementById('map-container')
  if (container) {
    resizeObserver.observe(container)
  }
}
export default function DetailedMap() {
  const { state } = useLocation();
  const { roadevent } = useRoadevent();
  var {latitude,longitude, error} = useMyPosition(true);
  const {token, logout} = useAuth();
  const { kort,getKort } = useFikapass();
  const popupElRef = useRef(null);
  

  const checka_in = async (token, kort,kontroll )=>{
    var res = await checkin(token, kort, kontroll)
    
    if('status' in res){
      //console.log(res)
      if(res['status']==='alreadycheckedin'){ 
        alert('Du har redan checkat in här. Testa ett annat fik.')

      }
      else if (res.status=='Unauthorized'){
        alert('Din session har gått ut. Logga in igen och ckecka in på nytt!')
        logout();
      }
      else if (res.status=='ok'){
        alert('Du har checkat in! Ha en fortsatt trevlig tur')
        getKort({},token)

      }
    }
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click()
  
    return
  }
  var closeTo = (fiket,latitude=0,longitude=0)=>{
    return (L.latLng(JSON.parse(fiket.latlon).reverse()).distanceTo([latitude,longitude])<550)
  }
  
  const mapRef = useRef();
  if (!latitude|!latitude){
    //latitude=0;
    //longitude=0;
    resizeMap(mapRef)
    //return <></>
  }

  return (
    <MapContainer ref={mapRef} id="map-container2" whenReady={() => resizeMap(mapRef)} center={position} zoom={13}  scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
     <LayerControl position="topright">
     <GroupedLayer checked group="Positioner" name="Min position" key="myposition">
        <Tracker></Tracker>
      </GroupedLayer>

      <GroupedLayer checked group="Positioner" name="Trafikvarningar" key="positioner">
        <LayerGroup>
          {roadevent?roadevent.map((road, index) => (
            road['Deviation'].map((roadevent,index2)=>(
              roadevent['MessageCodeValue']=="roadworks"?
              <Marker icon={createIcon(roadworkIcon)} key={index2} position={roadevent['Geometry']['WGS84'].slice(7,-1).split(' ').map((str)=>Number(str)).reverse()}>
              <Popup key={index2}>
                {roadevent['LocationDescriptor']}<br/>{roadevent['Message']}
              </Popup>
          </Marker>:<div key={'empty'+index2}></div>
            )
          ))):<></>}
        </LayerGroup>
      </GroupedLayer>
 
       <GroupedLayer checked group="Positioner" name="Fik" key="fik">
        <LayerGroup>
          {state.kontroller?state.kontroller.map((kontroll, index) => (
            <Marker key={'fik-'+index} position={JSON.parse(kontroll.fiket.latlon).reverse()} icon={createIcon(coffeeIcon)} >
               <Popup ref={popupElRef}>
                {kontroll.fiket.fik_name}<br/>{kontroll.fiket.description}
                <Button
                    onClick={()=>checka_in(token,kort[0].id,kontroll.id).then(res=>{})}
                    sx={{ my: 2, color: 'blue', display: 'block' }}
                    key={kontroll.fiket+'-knapp'}
                    disabled={!closeTo(kontroll.fiket,latitude,longitude)}
                >
                    Checka in
                </Button>
              </Popup>
              <Circle center={JSON.parse(kontroll.fiket.latlon).reverse()} radius={500} />
          </Marker>
          )):<></>}
        </LayerGroup>
      </GroupedLayer>

      <GroupedLayer checked name="OpenStreetMap" group="Bakgrundskarta" key="backgroundmap1">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">Stadia Maps</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </GroupedLayer>
      <GroupedLayer name="Esri - World streetmap" group="Bakgrundskarta" key="backgroundmap4">
        <TileLayer 
          attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012' 
          url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}' />
      </GroupedLayer>

        {state ? <GroupedLayer key={'runda'+'1'} checked name={state.title} info={true} item_prop={state} group="Landsvägsrundor">
        <Polyline
          pathOptions={{ fillColor: 'red', color: '#4d94ff' }}
          positions={state.latlon}
        >
        <Popup>
          {state.title} <br /> 
          {(state.length/1000).toFixed(1)} km <br/>
          {state.description}
          <br/>
          <a href={state.link}>Länk till runda på Strava</a>
        </Popup>
        </Polyline>
      </GroupedLayer>:null}
      </LayerControl> 
  </MapContainer>
  );
}


