import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import {baseUrl} from '../../baseUrl.js'
import Markdown from 'react-markdown'

import './NewsFeeds.css'
import './InstaFeeds.css'

const NewsFeed = ({...props}) => {
    const [newsfeeds, setNewsfeedsData] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();
        async function fetchInstagramPost () {
          try{
            axios
              .get(baseUrl+'/api/cms/news/')
              .then((resp)=>{
                setNewsfeedsData(resp.data)
            })
          }
        catch (err) {
              console.log('error', err)
          }}
        
        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])

    return (
        <div className="container" >
            {newsfeeds?.map((feed,index) => (<div key={index} style={{"verticalAlign": "top",
                "Display": "inlineBlock",
                "Width": "120px",
                "borderStyle": "solid",
                "borderWidth": "thin",
                "borderColor": "#DDDDDD",
                "borderRadius": "2px",
                "padding":"0.6rem"}}
                onClick={()=>navigate(`news/${feed.id}`)}>
                <div style={{"display": "flex", "justifyContent": "space-between"}} >
                    <h5>{feed.title}</h5>
                    <p style={{'fontSize':'10px','margin':'0px'}}>{feed.publish_date.substring(0, 10)} </p>
                </div>
                <img src={baseUrl+feed.img}/>
                    <Markdown children={feed.summary}></Markdown>
            </div>
            ))}
        </div>
    );
}

export default NewsFeed;