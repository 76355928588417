import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Typography from  '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import {isMobile} from 'react-device-detect';
// Man ska inte läsa från localStorage utan läsa från respektive hook
// På så sätt uppdateras renderingar som påverkas
//import { useLocalStorage } from "./hooks/useLocalStorage";

import logo from './cropped-cykelintresset1.webp';//logo.svg';
import './App.css';

import { useAuth } from "./hooks/useAuth";
import { useCMS } from "./hooks/cms";
import { useFikapass } from "./hooks/useFikapass";

const pages = [{'title':'OM CYKELINTRESSET','link':'about','submenu':[{'title':'OM CYKELINTRESSET','link':'about'},{'title':'BLI MEDLEM','link':'blimedlem'},{'title':'TRÄNINGSPOLICY','link':'traningspolicy'}]}, 
               {'title':'AKTIVITETER','link':'aktiviteter'}, 
               {'title':'ARRANGEMANG','link':'arrangemang'}, 
               {'title':'SIGR R & D','link':'sigr'},
               {'title':'FIKAPASSET','link':'fikapasset'}];
var settings = [{'title':'Profile', 'link':'profile'},
                  {'title':'Medlemskap', 'link':'medlemskap'},
                  {'title':'GDPR', 'link':'gdpr'},
                ];

function App() {
  const { getMenus,mainMenu,userMenu } = useCMS();
  //const [auth2] = useLocalStorage("auth2", null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSubNav, setAnchorElSubNav] = React.useState(null);
  const open = Boolean(anchorElSubNav);
  const handleClick = (event) => {
    setAnchorElSubNav(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElSubNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { logout,auth } = useAuth();
  const { kort, logout_fikapass } = useFikapass();
  if (mainMenu===null|userMenu===null)
  {
    getMenus();
  }
  const logoutchain = () =>{
    logout();
    logout_fikapass();
  }
  return (
    <div className="App" 
         style={{ "marginTop":'1em',
                  "maxWidth": "1180px",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  'backgroundColor':'#212121'
                  }}>
      {!isMobile?<Box style={{'position': 'relative','backgroundColor':'#fff', 'textAlign': 'center'}} sx={{height: { xs: '14vh', md: '14vh' } }}>
        <a href=".">
        <img src={logo} className="App-logo" alt="logo" />
        </a>
      </Box>:<></>}

      <AppBar style={{'backgroundColor':'#333','height':'3em','justifyContent':'center'}} position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {/* Burger main menu */}
                  {mainMenu?.map((page,index) => (
                    page.submenu?(<div key = {index}>
                    <MenuItem className="burger-nav" key={page.title} component={Link} to={`${page.link}`}>
                      <Typography textAlign="center" sx={{fontSize:'0.8rem',fontFamily: "Noto-Serif"}}>{page.title}</Typography>
                    </MenuItem>
                    {page.submenu.map((page2) => (<MenuItem className="burger-nav" 
                      key={page2.title}
                      component={Link} to={`${page2.link}`} state={page2.page}
                      sx={{fontSize:'0.7rem',fontFamily: "Noto-Serif",}}
                    >
                      {" - "+ page2.title}
                    </MenuItem> ))}
                    </div>):(<MenuItem
                    className="burger-nav"
                    key={page.title}
                    component={Link} to={`${page.link}`}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center" sx={{fontSize:'0.8rem',fontFamily: "Noto-Serif",}}>{page.title}</Typography>
                  </MenuItem>)
                  ))}
                </Menu>
              </Box>
              {isMobile?<Box style={{'position': 'relative','textAlign': 'center'}} sx={{fontFamily: "Noto-Serif", height: { xs: '5vh', md: '5vh' } }}>
                          <a href=".">
                          <p>CYKELINTRESSET</p>
                          </a>
                        </Box>:<></>}
              <div style ={{flexGrow:1}}>
                <Box sx={{ flexGrow: 0.9,justifyContent: 'center', display: { xs: 'none', md: 'flex',} }}>
                  
                  {mainMenu?.map((page,index) => (
                    page.submenu?(<div key = {index}>
                    <Button
                      key={page.title}
                      onClick={handleClick}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{ my: 2, color: 'white', display: 'block' ,'fontFamily': "Noto-Serif",fontWeight: 'bold'}}
                      style={{'paddingTop':'0px','paddingBottom':'0px',}}
                    >
                      <>
                        {page.title}<KeyboardArrowDownTwoToneIcon color='white' ></KeyboardArrowDownTwoToneIcon>
                      </>
                    </Button> 
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSubNav}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button', disablePadding: true
                      }}
                      style={{'paddingTop':'0px','paddingBottom':'0px',}}
                      >
                      {page.submenu.map((page2) => (<MenuItem
                          key={page2.title}
                          component={Link} to={`${page2.link}`} state={page2.page}
                          onClick={handleCloseNavMenu}
                          className='sub-menu-item3'
                          sx={{ fontSize:'0.875rem', 'backgroundColor':'#333', color: 'white', display: 'block' ,'fontFamily': "Noto-Serif",fontWeight: 'bold'}}
                        >
                          {page2.title}
                        </MenuItem> ))}
                      </Menu>
                    </div>):(<Button
                    key={page.title}
                    component={Link} to={`${page.link}`}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' ,'fontFamily': "Noto-Serif",fontWeight: 'bold'}}
                  >
                    {page.title}
                  </Button>)
                  ))}
                </Box>
              </div>
              {auth?<Box>
                <Tooltip title="Open profile Menu">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <MenuOpenIcon alt="Open profile Menu" sx={{color:'white'}}/>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar2"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.title} onClick={handleCloseUserMenu}>
                      <Typography 
                      textAlign="center" 
                      fontFamily="Noto-Serif" component={Link} to={setting.link}>{setting.title}</Typography>
                    </MenuItem>
                  ))
                  }
                  {/* Meny som endast ska generaras om användare har fikapass */}
                  {kort?<MenuItem key='fikapass' onClick={handleCloseUserMenu}>
                      <Typography 
                      textAlign="center" 
                      fontFamily="Noto-Serif" 
                      component={Link} 
                      to={'/fikapass'}
                      >Fikapass</Typography>
                    </MenuItem>:<></>}
                    {/* Logout meny som ska renderas för alla*/}
                    <MenuItem key='logout' onClick={handleCloseUserMenu}>
                      <Typography 
                      textAlign="center" 
                      fontFamily="Noto-Serif" 
                      component={Link} 
                      to={'/'}
                      onClick={logoutchain}>Logout</Typography>
                    </MenuItem>
                </Menu>
              </Box>:<Button
                    key={'skapakonto'}
                    component={Link} to={'/register'}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' ,'fontFamily': "Noto-Serif",fontWeight: 'bold'}}
                  >
                    <PersonAddIcon></PersonAddIcon>
                  </Button>}
            </Toolbar>
          </Container>
        </AppBar>
      <header className="App-header">
        <Outlet/>
      </header>
    </div>
  );
}

export default App;
