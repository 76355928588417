import { baseUrl } from '../baseUrl.js';

export const getqrimage = async (token,swish_token2)=>{
  const data = {
        swish_token: swish_token2
      };
    try {
        const response = await fetch(baseUrl+'/api/getqrimage_fikapass/', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+token
          },
          body: JSON.stringify(data),
          method: "POST"
        })
    
        if (response.status === 200) {

          return response;
        }
        else if(response.status==401){
          return ({'status':'Unauthorized'});
        }
      } catch (error) {
        console.error(error);
      }
} 
export const initate_fikapass_desktop2 = async (token,amount) => {
    //TODO: Om jag tar bort return här så öppnas swish appen
    // men då funkar inte desktop varianten
    var response = await fetch(baseUrl+'/api/fikapass/create_kort/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      body: JSON.stringify({'amount':amount}),
      })
    if (response.status==201){
        return response.json();
    }
    else if(response.status==208){
      return {'redirect':'alreadypaid'};
    }
    else if(response.status==401){
      return ({'status':'Unauthorized'});
    }
    else if(!response.ok) 
    {
        throw response;
    }
  };
  export const checkin = async (token,kort_id,kontroll_id) => {
    //TODO: Om jag tar bort return här så öppnas swish appen
    // men då funkar inte desktop varianten
    var response = await fetch(baseUrl+'/api/fikapass/checkin/',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'JWT '+token
      },
      body: JSON.stringify({'kort_id':kort_id,'kontroll_id':kontroll_id}),
      })
    if (response.status==201){
      return {'status':'ok'};
    }
    else if(response.status==208){
      return {'status':'alreadycheckedin'};
    }
    else if(response.status==401){
      return ({'status':'Unauthorized'});
    }
    else if(!response.ok) 
    {
        throw response;
    }
  };